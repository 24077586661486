<template>
    <div class="h-100">
        <div v-if="table.filter" class="bg-white brd-px-4 flex-r flex-jc-l flex-ai-fs flex-nowrap flex-gap-10" style="padding: 20px 12px 0">
            <slot name="filter" />
            <div class="flex-r flex-jc-r flex-ai-c flex-wrap flex-gap-10 flex-1 w-px-min-160">
                <div><el-button type="primary" @click="table.page.currentChange(1)">查询</el-button></div>
                <div><el-button @click="this.reload()">重置</el-button></div>
            </div>
        </div>
        <div class="bg-white p-l-12 p-r-12 p-b-12 brd-px-4 m-t-20">
            <slot name="table_tab" />
            <div class="flex-r flex-jc-sb flex-ai-c flex-wrap flex-gap-20 h-px-min-40 p-t-8">
                <div class="f-12 c-666 h-px-min-40 l-h-40 flex-1">
                    <slot name="table_title">
                        <span>数据列表（{{ table.title }}）</span>
                    </slot>
                </div>
                <div v-if="table.extras && table.extras.length > 0" class="flex-r flex-jc-r flex-ai-fs flex-wrap flex-gap-10">
                    <template v-for="(item, index) in table.extras" :key="index">
                        <template v-if="!item.comp">
                            <div v-if="!item.show || turn(item.show, null)">
                                <el-button v-bind="item.props" @click="item.action">{{ item.name }}</el-button>
                            </div>
                        </template>
                        <template v-else>
                            <component v-if="!item.show || turn(item.show, null)" :is="item.comp" :mode="item.mode" v-bind="item.props" @action="item.action"></component>
                        </template>
                    </template>
                </div>
            </div>
            <div flex="dir:top box:last" style="white-space: pre-wrap; margin-top: 8px">
                <el-table
                    v-loading="table.loading"
                    :data="table.datas"
                    v-bind="table.props"
                    border
                    :header-cell-style="{ background: '#F5F7FA', 'font-weight': 'bold', color: '#666' }"
                    @selection-change="handleSelectionChangeEvent"
                >
                    <el-table-column fixed="left" prop="check" type="selection" width="40px" v-if="table.select" />
                    <el-table-column
                        v-for="(item, i) in table.columns"
                        :key="i"
                        :fixed="item.fixed"
                        :label="item.title"
                        :prop="item.key"
                        :width="item.width"
                        :min-width="item.minWidth"
                        v-bind="item.props"
                    >
                        <template #default="scope" v-if="item.buttons">
                            <div class="flex-r flex-jc-l flex-ai-c flex-wrap">
                                <template v-for="(item, index) in item.buttons" :key="index">
                                    <el-button v-if="!item.show || turn(item.show, scope.row)" v-bind="item.props" @click="item.action(scope.row)">{{ item.name }}</el-button>
                                </template>
                            </div>
                        </template>
                        <template #default="scope" v-else>
                            <component :is="item.cellRenderer(scope)"></component>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex-r flex-jc-sb flex-ai-c flex-wrap flex-gap-20 m-t-12">
                <div class="flex-1">
                    <div v-if="table.footers && table.footers.length > 0" class="flex-r flex-jc-l flex-ai-c flex-wrap">
                        <template v-for="(item, index) in table.footers" :key="index">
                            <el-button v-if="!item.show || turn(item.show, null)" v-bind="item.props" @click="item.action(multipleSelection)">{{ item.name }}</el-button>
                        </template>
                    </div>
                </div>
                <div v-if="table.page">
                    <el-pagination
                        v-if="table.page.total >= 0"
                        :current-page="table.page.page"
                        :page-size="table.page.page_size"
                        :layout="table.page.total === Infinity ? ' sizes, prev, next, jumper' : 'total, sizes, prev, pager, next, jumper'"
                        :total="table.page.total"
                        :page-sizes="[10, 20, 30, 40, 50, 100]"
                        @size-change="table.page.sizeChange"
                        @current-change="table.page.currentChange"
                    />
                </div>
            </div>
        </div>
        <template v-if="table.drawer">
            <el-drawer
                :model-value="table.drawer.show"
                :title="table.drawer.title"
                append-to-body
                @close="table.drawer.close"
                :direction="table.drawer.direction || 'rtl'"
                :size="table.drawer.size || '70%'"
                style="overflow-y: scroll"
            >
                <el-scrollbar max-height="100%">
                    <slot name="drawercontent" />
                </el-scrollbar>
            </el-drawer>
        </template>
    </div>
</template>
<script>
import { ref } from 'vue'
import { turn } from '@/assets/js/extend/tableColumn'
export default {
    name: 'aw-tabler',
    inject: ['reload'],
    props: {
        table: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        if (props.table.action) props.table.action()

        const multipleSelection = ref([])
        const handleSelectionChangeEvent = (selection) => {
            multipleSelection.value = selection
        }

        return {
            turn,
            handleSelectionChangeEvent,
            multipleSelection
        }
    }
}
</script>
<style lang="scss">
.table-row-class {
    padding: 8px 0px;
}
.el-drawer {
    min-width: 400px !important;
}
.table-modal {
    .el-input-number,
    .el-select {
        min-width: 160px;
    }
}

.el-drawer__body {
    overflow-y: auto;
}
</style>
