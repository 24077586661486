<template>
    <!-- 定制手写组件：头部-页头组件 -->
    <!-- Auth:Dengwenwen -->
    <!-- Time:2023-10-20 -->
    <!-- 说明：支持导航跳转 -->
    <div class="c-333 text-left">
        <div class="flex-r flex-jc-l flex-ai-c flex-gap-10">
            <div v-for="(item, index) in menu_info" :key="index">
                <el-icon class="v-a-middle m-r-4" v-if="item.icon && item.icon != ''">
                    <component :is="item.icon"></component>
                </el-icon>
                <span class="v-a-middle m-r-4">{{ item.name }}</span>
                <el-icon class="v-a-middle" v-if="index + 1 < menu_info.length"><ArrowRight /></el-icon>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'aw-pageheader',
    props: {
        menu_info: {
            type: Array,
            default() {
                return []
            }
        }
    }
}
</script>
<style lang="scss" scoped></style>
