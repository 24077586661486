export default {
    // 例子
    example: {
        example_status: { 1: '启用', '-1': '禁用' }
    },
    // all平台
    platform: {
        mate_name: '智慧图文代理商平台'
    },
    // 我的导出
    export: {
        export_status: { 1: '等待导出', 2: '正在导出', 3: '导出成功', 4: '导出失败' },
        export_status_type: { 1: 'warning', 2: 'primary', 3: 'success', 4: 'danger' }
    },
    // order订单
    order: {
        order_status: { '-1': '待付款', 1: '交易完成', 2: '交易取消' },
        order_status_type: { '-1': 'warning', 1: 'success', 2: 'info' }
    }
}
