<template>
    <!-- 定制手写组件：日期选择器2次封装 -->
    <!-- Auth:Dengwenwen -->
    <!-- Time:2023-10-29 -->
    <!-- 说明：输入相关的属性输出时间戳等等 -->
    <div>
        <el-date-picker
            :disabled="button_disabled"
            v-model="selectTimesCustom"
            :size="size"
            :type="button_change ? button_change.type : 'daterange'"
            :format="button_change ? button_change.format : 'YYYY-MM-DD'"
            :placeholder="button_change ? button_change.chat : '请选择'"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            :clearable="clearable ? clearable : false"
            :disabled-date="date_disabled ? disabledDate : false"
            @change="(data) => MychangeTimesEvent(data)"
        />
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
    name: 'aw-date-picker',
    inject: ['reload'], //刷新引用
    props: ['Size', 'Button_Disabled', 'Button_Change', 'SelectTimesCustom', 'Date_Disabled', 'Out_Time', 'Clearable'],
    data() {
        return {
            size: '',
            // 请求时禁用
            button_disabled: false,
            // 选择周等按钮
            button_change: { chat: '请选择', type: 'daterange', format: 'YYYY-MM-DD' },
            // 自定义选中时间
            selectTimesCustom: '',
            // 今日后的数据禁用
            date_disabled: false,
            // 超出时间
            out_time: { time_size: 0, erro_message: '' },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token
        }
    },
    watch: {
        Size: {
            handler(newVal) {
                this.size = newVal
            },
            deep: true,
            immediate: true
        },
        Button_Disabled: {
            handler(newVal) {
                this.button_disabled = newVal
            },
            deep: true,
            immediate: true
        },
        Button_Change: {
            handler(newVal) {
                this.button_change = newVal
            },
            deep: true,
            immediate: true
        },
        SelectTimesCustom: {
            handler(newVal) {
                this.selectTimesCustom = newVal
            },
            deep: true,
            immediate: true
        },
        Date_Disabled: {
            handler(newVal) {
                this.date_disabled = newVal
            },
            deep: true,
            immediate: true
        },
        Out_Time: {
            handler(newVal) {
                this.out_time = newVal
            },
            deep: true,
            immediate: true
        },
        Clearable: {
            handler(newVal) {
                this.clearable = newVal
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        // 大于今天的禁选
        disabledDate(time) {
            return time.getTime() > (Date.now() / 1000 + 0 * 86400) * 1000
        },
        // 选中时间范围
        MychangeTimesEvent(data) {
            let datas = [data[0].valueOf() / 1000, data[1].valueOf() / 1000 + 86400 - 1]

            if (this.out_time && this.out_time.time_size > 0 && datas[1] - datas[0] > this.out_time.time_size) {
                // 提示超过时间差
                ElMessage({
                    type: 'warning',
                    message: this.out_time.erro_message ? this.out_time.erro_message : '选中时间差不能超过' + this.out_time.time_size + '秒'
                })
                this.selectTimesCustom = ''
            } else {
                this.selectTimesCustom = [datas[0], datas[1]]
            }

            this.$emit('change-data', this.selectTimesCustom)
        }
    },
    created() {}
}
</script>

<style scoped></style>
