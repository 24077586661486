<template>
    <!-- 定制手写组件：upload上传组件 -->
    <!-- Auth:Dengwenwen -->
    <!-- Time:2023-10-29 -->
    <!-- 说明：兼容oss直传与后端传输 -->
    <el-upload
        :action="api + uploadinfo.action"
        :headers="uploadinfo.headers"
        :method="uploadinfo.method"
        :data="uploadinfo.data"
        :multiple="uploadinfo.multiple"
        :limit="uploadinfo.limit"
        :list-type="uploadinfo.listtype"
        :file-list="uploadinfo.fileList"
        :show-file-list="uploadinfo.showFileList"
        :disabled="uploadinfo.disabled"
        :before-remove="beforeRemove"
        :on-remove="handleRemove"
        :on-preview="handlePreview"
        :on-exceed="(files, fileList) => handleExceed(files, fileList, uploadinfo.limit)"
        :on-change="handleChange"
        :before-upload="(file) => beforeUpload(file, uploadinfo.rules)"
        :on-success="onSuccess"
        :on-error="onError"
        :http-request="uploadinfo.httpRequest == true ? handleUpload : undefined"
    >
        <slot name="button">
            <el-button :size="uploadinfo.size" type="primary">{{ uploadinfo.text }}</el-button>
        </slot>
        <slot name="tips">
            <div class="el-upload-tip" v-if="uploadinfo.tip">
                <span>{{ uploadinfo.tip }}</span>
            </div>
        </slot>
    </el-upload>
</template>
<script>
import { config } from '@/assets/js'

export default {
    name: 'aw-upload',
    props: { mode: { type: Object, required: true } },
    watch: {
        mode: {
            handler(newVal) {
                let defaultVal = {
                    // 是否自定义上传（兼容OSS直传）默认是false，当为true表示属于自定义上传，覆盖Xhr行为，也就是action&headers的值无效化
                    httpRequest: false,
                    // 上传文件夹标识，默认是100通用
                    target_file: 100,
                    // 接口地址
                    action: '',
                    // 请求头设置
                    headers: {},
                    // 设置上传请求方法，默认是post
                    method: 'post',
                    // 附带参数
                    data: {},
                    // 是否支持多选
                    multiple: false,
                    // 上传文件限制，0标识无限制
                    limit: 0,
                    // 反显文件-类型: picture text picture-card
                    listtype: 'picture',
                    // 是否显示已上传文件列表
                    showFileList: false,
                    // 上传成功的-反显文件
                    // 注意：fileList的数据结构中必须包含name和url才能对应反显
                    // 样例：[{name:'*.jpeg',url:'https://***'}]
                    fileList: [],
                    // 是否禁用上传
                    disabled: false,
                    // 上传文件规则，默认是以下兼容图片&文件&视频&音频
                    rules: {
                        images: {
                            image_width: 0, //图片宽度，0表示不限宽
                            image_height: 0, //图片高度，0表示不限高
                            size: 1000, //文件大小（单位kb）
                            type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'], //文件格式
                            suffix: ['jpeg', 'jpg', 'gif', 'bmp', 'png'], //文件后缀-用于判断
                            typechart: 'png/bmp/gif/jpg/jpeg' //文件格式提示规则
                        },
                        files: {
                            size: 1000, //文件大小（单位kb）
                            type: [
                                'text/plain',
                                'application/pdf',
                                'application/msword',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/x-xls',
                                'application/vnd.ms-excel'
                            ], //文件格式
                            typechart: 'text/pdf/word/xls' //文件格式提示规则
                        },
                        video: {
                            size: 1000, //文件大小（单位kb）
                            type: ['video/mp4'], //文件格式
                            typechart: 'mp4' //文件格式提示规则
                        },
                        audio: {
                            size: 1000, //文件大小（单位kb）
                            type: ['audio/mpeg'], //文件格式
                            typechart: 'mp3' //文件格式提示规则
                        }
                    },
                    // 按钮文字
                    text: '点击上传',
                    // 按钮大小
                    size: 'small',
                    // 提示文案
                    tip: ''
                }

                defaultVal.headers[config.Authentication] = localStorage.getItem('token')
                this.uploadinfo = newVal.uploadinfo ? { ...defaultVal, ...newVal.uploadinfo } : defaultVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            api: config.api,
            uploadinfo: {}
        }
    },
    methods: {
        // 文件移除前-触发
        beforeRemove(file, fileList) {
            console.log('文件移除前:', file, fileList)
            if (file.status === 'success') return this.$confirm(`确定移除 ${file.name}？`)
            return
        },
        // 文件移除-触发
        handleRemove(file, fileList) {
            console.log('文件移除:', file, fileList)
        },
        // 点击已上传的文件-触发
        handlePreview(file) {
            console.log('点击已上传的文件:', file)
        },
        // 文件超出限制-触发
        handleExceed(files, fileList, limit) {
            this.$message.warning(`当前限制选择 ${limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
        },
        // 文件上传状态改变时-触发
        handleChange(file, fileList) {
            console.log('文件上传状态改变时:', file, fileList)
        },
        // 上传文件&video&audio-前端校验-判断
        filesMoreUploadEvent(file, rules, flag) {
            // 判断文件格式
            let types = rules[flag].type
            const isImage = types.includes(file.type)
            if (!isImage) {
                this.$message.error('上传文件只能是 ' + rules[flag].typechart + ' 文件格式!')
                return false
            }
            // 判断文件大小
            const isLtSize = file.size / 1024 < rules[flag].size
            if (!isLtSize) {
                this.$message.error('上传文件大小不能超过 ' + rules[flag].size + ' KB!')
                return false
            }
            return isImage && isLtSize
        },
        // 上传images-前端校验-判断
        async imagesUploadEvent(file, rules) {
            console.log('校验file：', file)
            // 判断图片格式
            let types = rules.images.type
            const isImage = types.includes(file.type)
            if (!isImage) {
                this.$message.error('上传图片只能是 ' + rules.images.typechart + ' 图片格式!')
                return false
            }
            // 判断图片大小
            const isLtSize = file.size / 1024 < rules.images.size
            if (!isLtSize) {
                this.$message.error('上传图片大小不能超过 ' + rules.images.size + ' KB!')
                return false
            }
            // 判断图片宽高尺寸
            const imageSize = await this.getImageSize(file)
            const image_width = imageSize.width
            const image_height = imageSize.height
            if (rules.images.image_width != 0 && rules.images.image_width != image_width) {
                this.$message.error('上传图片尺寸不符合,图片【宽度】必须是 ' + rules.images.image_width + ' px !')
                return false
            }
            if (rules.images.image_height != 0 && rules.images.image_height != image_height) {
                this.$message.error('上传图片尺寸不符合,图片【高度】必须是 ' + rules.images.image_height + ' px !')
                return false
            }

            return isImage && isLtSize && imageSize
        },
        // 获取上传的图片宽高
        getImageSize(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = function (e) {
                    const img = new Image()
                    img.src = e.target.result
                    img.onload = function () {
                        resolve({ width: img.width, height: img.height })
                    }
                    img.onerror = function () {
                        reject(new Error('无法加载图片'))
                    }
                }
                reader.onerror = function () {
                    reject(new Error('无法读取图片'))
                }
                reader.readAsDataURL(file)
            })
        },
        // 文件上传前-触发
        beforeUpload(file, rules) {
            console.log('文件上传前:', file)
            // 根据上传规则判断文件是否符合上传条件
            if (rules.files && rules.files.type.includes(file.type)) {
                return this.filesMoreUploadEvent(file, rules, 'files')
            } else if (rules.video && rules.video.type.includes(file.type)) {
                return this.filesMoreUploadEvent(file, rules, 'video')
            } else if (rules.audio && rules.audio.type.includes(file.type)) {
                return this.filesMoreUploadEvent(file, rules, 'audio')
            } else if (rules.images && rules.images.type.includes(file.type)) {
                return this.imagesUploadEvent(file, rules)
            } else {
                this.$message.error('上传文件的格式不正确!')
                return false
            }
        },
        // 文件上传成功-触发
        onSuccess(response, file, fileList) {
            console.log('文件上传成功:', response, file, fileList)
            // 当文件上传成功之后，让后端的数据存到result里
            // result的数据结构为：{"id":"12","url":"https://xxxx/file/xxxf22190cf2.jpg","name":"测试文件名"} [含id&图片地址]
            let success_file_info = response.result

            // 此时-已获取到相关数据-接下来可以对数据进行自由发挥了！！！
            console.log(success_file_info)

            // 将需要的数据-发送给父组件
            this.$emit('action', { actionData: file })
        },
        // 文件上传失败-触发
        onError(err, file, fileList) {
            console.log('文件上传失败:', err, file, fileList)
        },
        // *******************自定义上传-兼容OSS直传*******************
        // alioss上传文件-alioss限制最大不能超过5GB
        async putUpload(ObjName, fileUrl) {
            let OSS = require('ali-oss')
            let client = new OSS(config.oss_cfg)

            try {
                let result = await client.put(`${ObjName}`, fileUrl)
                return result
            } catch (error) {
                return Promise.reject(error)
            }
        },
        // 生成随机文件名称-规则八位随机字符，加下划线连接时间戳
        randomFileNameUUID() {
            function rx() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
            }
            return `${+new Date()}_${rx()}${rx()}`
        },
        // 自定义上传-覆盖XHR行为（兼容OSS直传）
        handleUpload(option) {
            console.log('option:', option)
            let random_name = this.randomFileNameUUID()
            let upFileName = config.bucket_file + config.target_file[this.uploadinfo.target_file || 100]
            let objName = random_name + '.' + option.file.name.split('.')[1]

            this.putUpload(`${upFileName}/${objName}`, option.file).then((res) => {
                console.log('res:', res)

                // 将需要的数据-发送给父组件
                this.$emit('action', { actionData: res })
            })
        }
    }
}
</script>
<style scoped>
.el-upload-tip {
    font-size: 12px;
    color: #999999;
    margin-top: 10px;
}
</style>
