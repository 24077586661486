<template>
    <!-- 定制手写组件：顶部Menu导航组件 -->
    <!-- Auth:Dengwenwen -->
    <!-- Time:2023-10-27 -->
    <!-- 说明：支持全局搜索，用户登录||注册，跳转管理后台等 -->
    <div class="my-fixed-box">
        <div class="flex-r flex-jc-sb flex-ai-c flex-gap-20 w-100">
            <slot name="collapse"></slot>
            <div class="flex-r flex-jc-r flex-ai-c flex-gap-20 flex-1">
                <slot name="start"></slot>
                <slot name="menu">
                    <div>
                        <el-menu
                            mode="horizontal"
                            :ellipsis="false"
                            :collapse="menu_actions.isCollapse"
                            :default-active="menu_info.default_active || '1'"
                            :background-color="menu_info.background_color || 'rgba(0, 0, 0, 0)'"
                            :text-color="menu_info.text_color || '#333'"
                        >
                            <div v-for="(item, index) in menu_items" :key="index">
                                <el-menu-item v-if="!item.children" :index="item.index" @click="item.action ? item.action(this) : clickMenuEvent(item.path, item)">
                                    <el-icon style="vertical-align: middle" :size="24">
                                        <component :is="item.icon"></component>
                                    </el-icon>
                                </el-menu-item>
                                <el-sub-menu v-else :index="item.index" class="my-submenu-box">
                                    <template #title>
                                        <el-icon style="vertical-align: middle" :size="24">
                                            <component :is="item.icon"></component>
                                        </el-icon>
                                    </template>
                                    <el-menu-item
                                        v-for="(jtem, jndex) in item.children"
                                        :key="jndex"
                                        :index="jtem.index"
                                        @click="jtem.action ? jtem.action(this) : clickMenuEvent(jtem.path, jtem)"
                                    >
                                        <el-icon style="vertical-align: middle" :size="24">
                                            <component :is="jtem.icon"></component>
                                        </el-icon>
                                        <span style="vertical-align: middle">{{ jtem.name }}</span>
                                    </el-menu-item>
                                </el-sub-menu>
                            </div>
                        </el-menu>
                    </div>
                </slot>
                <slot name="end">
                    <div v-if="menu_info.username">
                        <span :style="{ color: menu_info.text_color || '#333' }">{{ menu_info.username }}</span>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'aw-menu-header',
    props: {
        menu_info: {
            type: Object,
            default() {
                return {}
            }
        },
        menu_items: {
            type: Array,
            default() {
                return []
            }
        },
        menu_action: {
            type: Object
        }
    },
    watch: {
        menu_action: {
            handler(newVal) {
                let defaultVal = {
                    isCollapse: true
                }
                this.menu_actions = newVal ? { ...defaultVal, ...newVal } : defaultVal
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            menu_actions: {}
        }
    },
    methods: {
        // menu菜单点击事件
        clickMenuEvent(path, datas) {
            this.$emit('menu-click', { path, datas })
        }
    }
}
</script>
<style lang="scss" scoped>
.my-fixed-box {
    z-index: 2000;
    /* background: #ffffff; */
}

.el-menu--horizontal {
    border-bottom: none;
}

.my-submenu-box :deep(.el-sub-menu__title) {
    padding: 0 !important;
    padding-right: 32px !important;
}
</style>
