<template>
    <div class="c-white">
        <span>©2017-{{ this.footeryear }} 京硕信息版权所有 ICP证: </span>
        <a style="text-decoration: none; color: rgb(248, 175, 115)" href="https://beian.miit.gov.cn/" target="_blank">豫ICP备17032092号-1</a>
    </div>
</template>

<script>
export default {
    name: 'aw-footer',
    data() {
        return {
            footeryear: ''
        }
    },
    created() {
        // 获取当前年
        let now = new Date()
        this.footeryear = now.getFullYear()
    }
}
</script>
