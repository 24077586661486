<template>
    <div>
        <el-pagination
            :current-page="CurrentPage"
            :page-size="PageSize"
            :page-sizes="!PageSizes ? [10, 20, 30, 50, 100] : PageSizes"
            :small="true"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
export default {
    name: 'aw-pagination',
    props: ['total', 'pageSize', 'pageSizes', 'currentPage'],
    watch: {
        total: {
            handler(newVal) {
                this.Total = newVal
            },
            immediate: true,
            deep: true
        },
        pageSize: {
            handler(newVal) {
                this.PageSize = newVal
            },
            immediate: true,
            deep: true
        },
        pageSizes: {
            handler(newVal) {
                this.PageSizes = newVal
            },
            immediate: true,
            deep: true
        },
        currentPage: {
            handler(newVal) {
                this.CurrentPage = newVal
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            PageSizes: [10, 20, 30, 50, 100],
            PageSize: 10,
            CurrentPage: 1,
            Total: 40
        }
    },
    methods: {
        handleSizeChange(val) {
            this.$emit('size-change', val)
        },
        handleCurrentChange(val) {
            this.$emit('current-change', val)
        }
    }
}
</script>

<style scoped lang="scss"></style>
