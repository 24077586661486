<template>
    <div id="app">
        <el-config-provider :locale="locale">
            <router-view v-if="isRouterAlive" :key="$route.fullPath"></router-view>
        </el-config-provider>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default defineComponent({
    name: 'App',
    provide() {
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            isRouterAlive: true,
            locale: zhCn
        }
    },
    methods: {
        // 页面刷新
        reload() {
            this.isRouterAlive = false
            this.$nextTick(function () {
                this.isRouterAlive = true
            })
        }
    }
})
</script>

<style>
#app {
    margin: 0;
    padding: 0;
    color: #333333;
    font-family: Alimama_Regular;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*table表中识别 /n 数据换行*/
.el-table .cell {
    white-space: pre-line !important;
}

/*全局控制-滑动条*/
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffffff;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 2px solid transparent;
}

::-webkit-scrollbar-track {
    border-radius: 999px;
    border: 2px solid transparent;
}

::-webkit-scrollbar-thumb {
    min-height: 50px;
    background-clip: content-box;
    box-shadow: 0 0 0 5px var(--el-border-color) inset;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
</style>
