import moment from 'moment'
import { ElMessageBox } from 'element-plus'
const util = {
    // 随机码
    guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    },

    sleep(time = 500) {
        return new Promise((resolve) => setTimeout(resolve, time))
    },

    fullScreen(el) {
        if (document.fullscreenEnabled) {
            if (document.fullscreenElement) {
                document.exitFullscreen()
            } else {
                if (!el) el = document.body
                el.requestFullscreen()
            }
        }
    },

    getOs() {
        const agent = navigator.userAgent.toLocaleLowerCase()
        const ios = /iPad|iPhone|iPod/.test(agent) && !window.MSStream
        const mac = /macintosh|mac os x/i.test(agent) && !window.MSStream
        const android = /Android/.test(agent) && !window.MSStream
        const windows = /win32|wow32|win64|wow64/.test(agent)
        return windows ? 'windows' : mac ? 'macos' : android ? 'android' : ios ? 'ios' : 'other'
    },

    format(time, format = 'yyyy-MM-DD HH:mm:ss') {
        return time ? moment(parseInt(time)).format(format) : ''
    },

    // 深拷贝
    deepcopy(obj, cache = []) {
        function find(list, f) {
            return list.filter(f)[0]
        }

        // just return if obj is immutable value
        if (obj === null || typeof obj !== 'object') {
            return obj
        }

        // if obj is hit, it is in circular structure
        const hit = find(cache, (c) => c.original === obj)
        if (hit) {
            return hit.copy
        }

        const copy = Array.isArray(obj) ? [] : {}
        // put the copy into cache at first
        // because we want to refer it in recursive deepCopy
        cache.push({
            original: obj,
            copy
        })

        Object.keys(obj).forEach((key) => {
            copy[key] = util.deepcopy(obj[key], cache)
        })

        return copy
    },

    tree: {
        turn(obj, field = 'id', childrenKey = 'children') {
            if (obj instanceof Array) {
                obj.map((item) => {
                    if (item[childrenKey]) {
                        item[childrenKey] = util.tree.turn(obj[childrenKey], field, childrenKey)
                    }
                })
                return obj
            } else if (obj instanceof Object) {
                let arr = []
                for (let key in obj) {
                    const item = obj[key]
                    item[field] = item[field] || key
                    if (item[childrenKey]) {
                        item[childrenKey] = util.tree.turn(item[childrenKey], field, childrenKey)
                    }
                    arr.push(item)
                }
                return arr
            }
        },
        map(tree, fn, childrenKey = 'children') {
            return tree.map((item) => {
                if (item[childrenKey]) item[childrenKey] = util.tree.map(item[childrenKey], fn, childrenKey)
                return fn(item)
            })
        },
        forEach(tree, fn, childrenKey = 'children') {
            tree.map((item) => {
                if (item[childrenKey]) util.tree.forEach(item[childrenKey], fn, childrenKey)
                fn(item)
            })
        },
        // 扁平化
        toMaps(tree, parentId, field = 'id', childrenKey = 'children') {
            return tree.reduce(
                (pre, cur) => [
                    ...pre,
                    parentId ? { parentId, ...cur } : cur,
                    ...(cur[childrenKey]?.length ? util.tree.toMaps(cur[childrenKey], cur[field], field, childrenKey) : [])
                ],
                []
            )
        },

        find(tree, fn, field = 'id', parentKey = 'parentId', childrenKey = 'children') {
            const maps = util.tree.toMaps(tree, undefined, field, childrenKey)
            let node = maps.find(fn)
            let result = []
            if (node) {
                result.push(node[field])
                while (node) {
                    node = maps.find((item) => item[field] === node[parentKey])
                    if (node) result.push(node[field])
                }
            }
            return result.reverse()
        }
    },

    confirm(option) {
        return new Promise((resolve, reject) => {
            const message = option.message || `是否${option.title}？ ${option.value || ''}`
            option.type = option.type || 'info'
            option.beforeClose = async (res, instance, done) => {
                if (res === 'confirm') {
                    instance.confirmButtonLoading = true
                    try {
                        const payload = await option.action(instance.inputValue)
                        resolve(payload)
                        done()
                    } catch (e) {
                        console.error(e)
                        reject()
                    }
                    instance.confirmButtonLoading = false
                } else {
                    reject()
                    done()
                }
            }
            if (option.required || option.valid || option.pattern) {
                option.showInput = true
                option.inputValidator = option.valid || ((val) => !!val)
                option.inputPattern = option.pattern
                option.inputErrorMessage = `请填写正确的${option.name || ''}`
                option.inputPlaceholder = `请输入${option.name || ''}`
                ElMessageBox.prompt(message, option.title, option)
            } else {
                ElMessageBox.confirm(message, option.title, option)
            }
        })
    }
}
util.os = util.getOs()
export default util
