<template>
    <!-- 定制手写组件：左侧-竖向2级菜单 -->
    <!-- Auth:Dengwenwen -->
    <!-- Time:2023-10-27 -->
    <!-- 说明：属于竖向menu菜单的二次封装，通过router激活index的菜单，且default_active是父级组件通过router_path改变值传递进当前组件的 -->
    <div class="h-100">
        <el-menu
            :default-active="default_active"
            :collapse="menu_actions.isCollapse"
            unique-opened
            router
            :background-color="menu_info.background_color || 'rgba(0, 0, 0, 0)'"
            :text-color="menu_info.text_color || '#333'"
        >
            <template v-for="(item, index) in menu_items_options" :key="index">
                <el-menu-item v-if="!item.child" :index="item.path">
                    <template #title>
                        <el-icon v-if="item.icon" class="v-a-middle l-h-10 p-r-4" :size="16">
                            <component :is="item.icon"></component>
                        </el-icon>
                        <span class="v-a-middle">{{ item.name }}</span>
                    </template>
                </el-menu-item>
                <el-sub-menu v-else :index="item.path">
                    <template #title>
                        <el-icon v-if="item.icon" class="v-a-middle l-h-10 p-r-4" :size="16">
                            <component :is="item.icon"></component>
                        </el-icon>
                        <span class="v-a-middle">{{ item.name }}</span>
                    </template>
                    <el-menu-item :index="jtem.path" v-for="(jtem, jndex) in item.child" :key="jndex">
                        <template #title>
                            <el-icon v-if="jtem.icon" class="v-a-middle l-h-10 p-r-4" :size="16">
                                <component :is="jtem.icon"></component>
                            </el-icon>
                            <span class="v-a-middle">{{ jtem.name }}</span>
                        </template>
                    </el-menu-item>
                </el-sub-menu>
            </template>
        </el-menu>
    </div>
</template>
<script>
import tool from '@/assets/js/tool.js'
export default {
    name: 'aw-menu',
    props: {
        default_active: {
            type: String,
            default: ''
        },
        menu_items: {
            type: Array,
            default() {
                return []
            }
        },
        menu_info: {
            type: Object,
            default() {
                return {}
            }
        },
        menu_action: {
            type: Object
        }
    },
    watch: {
        menu_items: {
            handler(newVal) {
                const all_id_path = tool.traverseChildren(this.$router.options.routes)
                this.addPathToChildren(newVal, all_id_path)
                this.menu_items_options = newVal
            },
            deep: true,
            immediate: true
        },
        menu_action: {
            handler(newVal) {
                let defaultVal = {
                    isCollapse: false
                }
                this.menu_actions = newVal ? { ...defaultVal, ...newVal } : defaultVal
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            menu_actions: {},
            menu_items_options: []
        }
    },
    methods: {
        addPathToChildren(arr, path_array) {
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i]
                let find_path = path_array.filter((n) => n.id == obj.id)
                obj.path = find_path.length > 0 ? find_path[0]['path'] : '/a' + obj.id
                obj.icon = 'Odometer'

                if (obj.child && obj.child.length > 0) {
                    this.addPathToChildren(obj.child, path_array) // 递归遍历子对象的child
                }
            }
        }
    }
}
</script>
<style scoped>
.el-menu {
    border-right: solid 0px var(--el-menu-border-color);
}
</style>
