import { createRouter, createWebHistory } from 'vue-router'
import { ref } from 'vue'
import { dict } from '../assets/js'
const mate_name = ref(dict.platform.mate_name)

const routes = [
    {
        //登录
        path: '/',
        name: 'login',
        meta: { name: mate_name.value },
        component: () => import('@/view/login/login.vue')
    },
    {
        name: 'index',
        path: '/index',
        meta: { name: '主页' },
        component: () => import('../view/index/index.vue'),
        children: [
            {
                name: 'user',
                path: '/user',
                meta: { name: '用户' },
                children: [
                    {
                        id: '5',
                        name: 'userList',
                        path: '/userList',
                        meta: { name: '用户列表 | ' + mate_name.value },
                        component: () => import('@/view/user/user/userList.vue')
                    }
                ]
            },
            {
                name: 'mall',
                path: '/mall',
                meta: { name: '商城' },
                children: [
                    {
                        id: '6',
                        name: 'order',
                        path: '/order',
                        meta: { name: '订单信息 | ' + mate_name.value },
                        component: () => import('@/view/mall/order/order.vue')
                    }
                ]
            },
            {
                name: 'finance',
                path: '/finance',
                meta: { name: '账单' },
                children: [
                    {
                        id: '7',
                        name: 'account',
                        path: '/account',
                        meta: { name: '账户总览 | ' + mate_name.value },
                        component: () => import('@/view/finance/account/account.vue')
                    },
                    {
                        id: '8',
                        name: 'agentBillDetail',
                        path: '/agentBillDetail',
                        meta: { name: '代理商账单明细 | ' + mate_name.value },
                        component: () => import('@/view/finance/agentBill/agentBillDetail.vue')
                    },
                    {
                        id: '10',
                        name: 'withdrawRecord',
                        path: '/withdrawRecord',
                        meta: { name: '提现记录 | ' + mate_name.value },
                        component: () => import('@/view/finance/withdrawRecord/withdrawRecord.vue')
                    },
                ]
            },
            {
                name: 'other',
                path: '/other',
                meta: { name: '其他' },
                children: [
                    {
                        id: '9',
                        name: 'exportList',
                        path: '/exportList',
                        meta: { name: '我的导出 | ' + mate_name.value },
                        component: () => import('@/view/other/export/exportList.vue')
                    }
                ]
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [...routes]
})

// 路由后
router.afterEach((to) => {
    let name = to.query.meta_name || to.meta.name
    if (name) {
        document.title = name
        for (let key in to.query) {
            const res = parseInt(to.query[key])
            if (!isNaN(res)) to.query[key] = res
        }
    }
})

export default router
