const tool = {
    // 遍历数组对象获取所有children下的id以及path
    // 主要用于menu菜单与后端的id匹配等
    traverseChildren(arr, result = []) {
        // 遍历数组中的每个对象
        for (const obj of arr) {
            // 如果当前对象有id和path属性
            if (obj.hasOwnProperty('id') && obj.hasOwnProperty('path')) {
                // 将id和path组成新对象，并添加到结果数组中
                result.push({ id: obj.id, path: obj.path })
            }
            // 如果当前对象有children属性且是一个数组
            if (obj.hasOwnProperty('children') && Array.isArray(obj.children)) {
                // 递归调用遍历子级的children属性
                tool.traverseChildren(obj.children, result)
            }
        }

        return result
    }
}

export default tool
