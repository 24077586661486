import util from '../util'
export const turn = (handler, data) => (handler instanceof Function ? handler(data) : handler)
export const tableColumn = (cols) => {
    return cols.map((item) => {
        let render
        if (item.route) {
            render = (data) => {
                const props = turn(item.props, data) || {}
                props.disabled = props.disabled || false
                let to = turn(item.route, data)
                if (props.disabled) {
                    return data[item.key]
                } else {
                    return <router-link to={to}>{data[item.key]}</router-link>
                }
            }
        } else if (item.switch) {
            if (!item.key) item.key = 'status'
            render = (data) => {
                const value = data[item.key]
                const props = turn(item.props, data) || {}
                props.disabled = props.disabled || false
                return (
                    <el-switch
                        model-value={value === 1}
                        {...props}
                        onChange={async () => {
                            if (item.confirm) {
                                await util.confirm({ title: value === 1 ? '禁用' : '启用', action: () => item.switch(data) })
                            } else {
                                await item.switch(data)
                            }
                            data[item.key] = value === 1 ? -1 : 1
                        }}
                    ></el-switch>
                )
            }
        } else if (item.tag) {
            render = (data) => {
                let tags = turn(item.tag, data)
                if (tags instanceof Array) {
                    return tags.map(({ props, text }) => (
                        <el-tag class='m-r-4 m-b-4' {...props}>
                            {text}
                        </el-tag>
                    ))
                } else {
                    if (tags === true) tags = { type: 'info' }
                    return <el-tag {...tags}>{data[item.key]}</el-tag>
                }
            }
        } else if (item.time) {
            render = (data) => util.format(data[item.key] * 1000, item.time === true ? undefined : item.time)
        } else if (item.image) {
            render = (data) => {
                const src = data[item.key]
                if (src) {
                    const imgs = src.split(',')
                    return imgs.map((item) => (
                        <el-image
                            class='m-r-4'
                            src={item + '?x-oss-process=image/resize,w_40'}
                            fit='cover'
                            style={{ width: '40px', height: '40px', borderRadius: '5px' }}
                            preview-src-list={imgs}
                            preview-teleported={true}
                        />
                    ))
                }
                return ''
            }
        } else if (item.render) {
            render = (data) => item.render(data)
        } else {
            render = (data) => {
                return <span>{data[item.key]}</span>
            }
        }

        if (render)
            item.cellRenderer = (record) => {
                const result = render(record.row)
                return typeof result === 'string' || Array.isArray(result) ? <>{result}</> : result
            }
        item.dataKey = item.key
        return item
    })
}
