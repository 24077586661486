import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn' // 配置国际化-兼容时间组件
import App from './App.vue'
import router from './router/index'
import axios from 'axios'
import * as echarts from 'echarts' //echarts
import './assets/style/base.scss' // 全局样式
import './assets/style/theme.scss'
import { ElMessage } from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'flex.css/dist/flex.css'

const app = createApp(App, {})
app.use(ElementPlus, { locale: zhCn }) //挂载elementplus,且设置中文
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(router)
app.config.globalProperties.$echarts = echarts //配置的echarts图表
app.config.globalProperties.$axios = axios //配置axios的原型&更改(使用globalProperties进行全局配置)

const manageCompViews = require.context('./components', true, /\.vue$/)
manageCompViews.keys().forEach((fileName) => {
    const comp = manageCompViews(fileName)
    app.component(comp.default.name, comp.default)
})

app.mount('#app')
